import { jsonEquals } from "./objectUtils"

export const range = (start: number, end: number): number[] => {
  return new Array(end - start + 1).fill(undefined).map((x, i) => i + start)
}

export const indexes = (startIndex: number, count: number) =>
  range(startIndex, startIndex + count - 1)

export const isFirst = (index: number) => index === 0
export const isNotFirst = (index: number) => index > 0

export const isLast = (index: number, array: any[]) =>
  index === array.length - 1

export const isNotLast = (index: number, array: any[]) =>
  index < array.length - 1

export const isNotExternal = (index: number, array: any[]) =>
  isNotFirst(index) && isNotLast(index, array)

export const sumValues = (values: number[]) => values.reduce((a, b) => a + b, 0)

export const distinct = <T>(values: T[]) => {
  return values.filter((n, i) => values.indexOf(n) === i)
}

export const distinctElements = <T>(
  values: T[],
  comparer: (value: T) => any
) => {
  return values.filter(
    (other, i, arr) =>
      arr.findIndex((t) => comparer(t) === comparer(other)) === i
  )
}

export const jsonDistinct = <T>(values: T[]) => {
  return distinctElements(values, (x) => JSON.stringify(x))
}

export const selectMany = <TArr, TVal>(
  array: TArr[],
  selector: (value: TArr) => TVal[]
): TVal[] => {
  return array.reduce(function (a, b) {
    return a.concat(selector(b))
  }, [] as TVal[])
}

export const flatten = <T>(array: T[][]): T[] => selectMany(array, (x) => x)

export const sort = <T>(array: T[], property: (element: T) => number) =>
  array.sort((a, b) => property(a) - property(b))
export const sortDesc = <T>(array: T[], property: (element: T) => number) =>
  sort(array, (x) => -property(x))

export const first = <T>(array: T[]) => array[0]
export const firstOrUndefined = <T>(array: T[]) =>
  array.length > 0 ? first(array) : undefined
export const firstOrDefault = <T>(array: T[], defaultValue: T) =>
  array.length > 0 ? first(array) : defaultValue

export const last = <T>(array: T[]) => array[array.length - 1]
export const lastOrUndefined = <T>(array: T[]) =>
  array.length > 0 ? last(array) : undefined
export const lastOrDefault = <T>(array: T[], defaultValue: T) =>
  array.length > 0 ? last(array) : defaultValue

export const findOrDefault = <T>(
  array: T[],
  filter: (element: T) => boolean,
  defaultValue: T
) => array.find(filter) ?? defaultValue

export const findMatching = <T>(array: T[], value: T) =>
  array.find((x) => jsonEquals(x, value))

export const without = <T>(array: T[], value: T) =>
  array.filter((x) => x !== value)
export const withoutValues = <T>(array: T[], values: T[]) => {
  let items = array
  for (const value of values) {
    items = without(items, value)
  }
  return items
}

export const take = <T>(array: T[], count: number, start = 0) => {
  return array.slice(start, start + count)
}

export const skip = <T>(array: T[], count: number) => {
  return array.slice(count)
}

export const containsString = <T>(
  array: T[],
  selector: (item: T) => string,
  value: string,
  ignoreCase = false
) => {
  return (
    array
      .map(selector)
      .map((x) => (ignoreCase ? x.toLowerCase() : x))
      .indexOf(ignoreCase ? value.toLowerCase() : value) >= 0
  )
}

export const containsMatching = <T>(array: T[], value: T) =>
  findMatching(array, value) !== undefined

export const isEmpty = <T>(array: T[]) => array.length === 0
export const isNotEmpty = <T>(array: T[]) => !isEmpty(array)

const compareFields = <T>(a: T, b: T, selector: (value: T) => any): number => {
  if (selector(a) < selector(b)) {
    return -1
  }
  if (selector(a) > selector(b)) {
    return 1
  }
  return 0
}

export const byField = <T>(
  selector: (value: T) => any
): ((a: T, b: T) => number) => {
  return function (a: T, b: T) {
    return compareFields(a, b, selector)
  }
}

export const byFieldDesc = <T>(
  selector: (value: T) => any
): ((a: T, b: T) => number) => {
  return function (a: T, b: T) {
    return -compareFields(a, b, selector)
  }
}

export const groupBy = <T>(
  array: T[],
  key: (element: T) => any
): Map<any, T[]> =>
  array.reduce((objectsByKeyValue: Map<any, T[]>, obj) => {
    const keyValue = key(obj)
    objectsByKeyValue.set(
      keyValue,
      (objectsByKeyValue.get(keyValue) ?? []).concat(obj)
    )
    return objectsByKeyValue
  }, new Map<any, T[]>())

export const toMap = <TKey, TVal>(
  array: TVal[],
  key: (val: TVal) => TKey
): Map<TKey, TVal> => {
  const map = new Map<TKey, TVal>()
  array.forEach((x) => map.set(key(x), x))
  return map
}

interface Dictionary<T> {
  [key: string]: T
}

export const toDictionary = <T>(
  array: T[],
  key: (val: T) => string
): Dictionary<T> => {
  const dict: Dictionary<T> = {}
  array.forEach((x) => (dict[key(x)] = x))
  return dict
}

export const notUndefined = <T>(x: T | undefined): x is T => {
  return x !== undefined
}
