import { graphql, useStaticQuery } from "gatsby"
import React from "react"

interface PrismicRepoSettings {
  repoName: string
}

const PrismicRepoSettingsContext = React.createContext<PrismicRepoSettings>({
  repoName: "",
})

interface Props {
  children: any
}

export const PrismicRepoSettingsProvider = ({ children }: Props) => {
  const { sitePlugin } = useStaticQuery(graphql`
    query PrismicPluginQuery {
      sitePlugin(name: { eq: "gatsby-source-prismic" }) {
        pluginOptions {
          repositoryName
        }
      }
    }
  `)
  return (
    <PrismicRepoSettingsContext.Provider
      value={{
        repoName: sitePlugin.pluginOptions.repositoryName,
      }}
    >
      {children}
    </PrismicRepoSettingsContext.Provider>
  )
}

export default PrismicRepoSettingsContext
