// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-lang-en-tsx": () => import("./../../../src/pages/lang/en.tsx" /* webpackChunkName: "component---src-pages-lang-en-tsx" */),
  "component---src-pages-lang-index-tsx": () => import("./../../../src/pages/lang/index.tsx" /* webpackChunkName: "component---src-pages-lang-index-tsx" */),
  "component---src-pages-lang-it-tsx": () => import("./../../../src/pages/lang/it.tsx" /* webpackChunkName: "component---src-pages-lang-it-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-templates-contacts-tsx": () => import("./../../../src/templates/contacts.tsx" /* webpackChunkName: "component---src-templates-contacts-tsx" */),
  "component---src-templates-iubenda-tsx": () => import("./../../../src/templates/iubenda.tsx" /* webpackChunkName: "component---src-templates-iubenda-tsx" */)
}

