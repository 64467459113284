import React from "react"
import { byField, byFieldDesc, sort } from "../../utils/arrayUtils"
import { AppTheme } from "./types"

export const AppThemesContext = React.createContext<AppTheme[]>([])

export const useAppThemes = () => React.useContext(AppThemesContext)

export const useDefaultAppTheme = () =>
  sort(
    useAppThemes(),
    byFieldDesc((x) => x.default),
    byField((x) => x.name)
  )?.[0]

export const useAppTheme = (name: string) =>
  useAppThemes().find((x) => x.name === name)
