import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  WebsiteIndex,
  WebsiteIndex_allPrismicWebsiteIndex_nodes as WebsiteIndexNode,
} from "../../__generated__/WebsiteIndex"
import { toMap } from "../../utils/arrayUtils"
import { FALLBACK_LANGUAGE } from "../../config/i18n"

export interface WebsiteContextData {
  lang: string
  name: string
  home: any
}

export interface WebsiteContextInfo {
  getData(lang: string): WebsiteContextData
}

class WebsiteContextMap implements WebsiteContextInfo {
  private map = new Map<string, WebsiteContextData>()
  constructor(pages: WebsiteContextData[]) {
    this.map = toMap(pages, (x) => x.lang.toLowerCase())
  }

  getData = (lang: string) =>
    this.map.get(lang.toLowerCase()) ??
    (this.map.get(FALLBACK_LANGUAGE) as WebsiteContextData)
}

const WebsiteContext = React.createContext<WebsiteContextInfo>(
  new WebsiteContextMap([])
)

interface Props {
  children: React.ReactNode
}

const toWebsiteContextInfo = (node: WebsiteIndexNode): WebsiteContextData => ({
  lang: node.lang,
  name: node.data?.website_name ?? "",
  home: node.data?.home_page,
})

export const WebsiteContextProvider = ({ children }: Props) => {
  const { allPrismicWebsiteIndex } = useStaticQuery<WebsiteIndex>(graphql`
    query WebsiteIndex {
      allPrismicWebsiteIndex {
        nodes {
          lang
          data {
            website_name
            home_page {
              id
            }
          }
        }
      }
    }
  `)
  return (
    <WebsiteContext.Provider
      value={
        new WebsiteContextMap(
          allPrismicWebsiteIndex.nodes.map(toWebsiteContextInfo)
        )
      }
    >
      {children}
    </WebsiteContext.Provider>
  )
}

export default WebsiteContext
