import React from "react"
import PrismicPreview from "./PrismicPreview"

const Scripts = () => {
  return (
    <>
      <PrismicPreview />
    </>
  )
}

export default Scripts
