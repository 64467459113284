import React from "react"
import { PreviewStoreProvider } from "gatsby-source-prismic"
import { PrismicRepoSettingsProvider } from "../context/settings"
import PrismicScriptImport from "./script"
import { PrismicWebsiteSettingsProvider } from "./settings"
import { PrismicWebsiteThemeProvider } from "./theme"

interface Props {
  children: any
}

const PrismicProviders = ({ children }: Props) => {
  return (
    <PreviewStoreProvider>
      <PrismicRepoSettingsProvider>
        <PrismicWebsiteSettingsProvider>
          <PrismicWebsiteThemeProvider>
            {children}
            <PrismicScriptImport />
          </PrismicWebsiteThemeProvider>
        </PrismicWebsiteSettingsProvider>
      </PrismicRepoSettingsProvider>
    </PreviewStoreProvider>
  )
}

export default PrismicProviders
