import React from "react"
import { getQueryParam } from "../../../../common/utils/urlUtils"
import PrismicScript from "../../scripts/PrismicScript"

const PrismicScriptImport = () => {
  const [loadScript, setLoadScript] = React.useState(false)

  React.useEffect(() => {
    const toolbarParam = getQueryParam(window.location, "toolbar")
    if (toolbarParam !== undefined && toolbarParam !== "false") {
      setLoadScript(true)
    }
  }, [])

  return <>{loadScript && <PrismicScript />}</>
}

export default PrismicScriptImport
