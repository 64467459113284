import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { byField, byFieldDesc, sort } from "../../../../common/utils/arrayUtils"
import {
  WebsiteSettings,
  WebsiteSettings_allPrismicWebsiteSettings_nodes as WebsiteSettingsNode,
} from "../../types/settings"
import WebsiteSettingsContext, {
  WebsiteContextData,
  WebsiteContextMap,
} from "../../../../common/context/site"

interface Props {
  children: React.ReactNode
}

const toWebsiteContextInfo = (
  node: WebsiteSettingsNode
): WebsiteContextData => ({
  lang: node.lang,
  name: node.data?.website_name ?? "",
  home: node.data?.home_page,
})

const getFallbackLanguage = (nodes: WebsiteSettingsNode[]) =>
  sort(
    nodes,
    byFieldDesc((x: any) => x.data?.is_fallback_language),
    byField((x: any) => x.lang)
  )?.[0].lang

export const PrismicWebsiteSettingsProvider = ({ children }: Props) => {
  const { allPrismicWebsiteSettings } = useStaticQuery<WebsiteSettings>(graphql`
    query WebsiteSettings {
      allPrismicWebsiteSettings {
        nodes {
          lang
          data {
            website_name
            home_page {
              id
            }
            is_fallback_language
          }
        }
      }
    }
  `)
  return (
    <WebsiteSettingsContext.Provider
      value={
        new WebsiteContextMap(
          getFallbackLanguage(allPrismicWebsiteSettings.nodes),
          allPrismicWebsiteSettings.nodes.map(toWebsiteContextInfo)
        )
      }
    >
      {children}
    </WebsiteSettingsContext.Provider>
  )
}
