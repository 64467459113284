import React from "react"
import { toMap } from "../../utils/arrayUtils"

export interface WebsiteContextData {
  lang: string
  name: string
  home: any
}

export interface WebsiteContextInfo {
  getData(lang: string): WebsiteContextData
}

export class WebsiteContextMap implements WebsiteContextInfo {
  private map = new Map<string, WebsiteContextData>()
  constructor(private fallbackLanguage: string, pages: WebsiteContextData[]) {
    this.map = toMap(pages, (x) => x.lang.toLowerCase())
  }

  getData = (lang: string) =>
    this.map.get(lang.toLowerCase()) ??
    (this.map.get(this.fallbackLanguage) as WebsiteContextData)
}

const WebsiteSettingsContext = React.createContext<WebsiteContextInfo>(
  new WebsiteContextMap("", [])
)

export default WebsiteSettingsContext
