import { library } from "@fortawesome/fontawesome-svg-core"
import {
  fab,
  faFacebookF,
  faGoogle,
  faTwitter,
  faYoutube,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons"

import { fas, faEnvelope, faLink } from "@fortawesome/free-solid-svg-icons"

export const importIcons = () => {
  library.add(
    fab,
    fas,
    faFacebookF,
    faYoutube,
    faInstagram,
    faGoogle,
    faTwitter,
    faWhatsapp,
    faEnvelope,
    faLink
  )
}
