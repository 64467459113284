import { red } from "@material-ui/core/colors"
import { createMuiTheme } from "@material-ui/core/styles"
import {
  primaryColor,
  secondaryColor,
  fontSizes,
  successColor,
  lineHeights,
  borderRadius,
} from "./variables"

const theme = createMuiTheme({
  palette: {
    primary: primaryColor,
    secondary: secondaryColor,
    success: successColor,
    error: {
      main: red.A400,
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgb(255, 255, 255)",
    },
    background: {
      default: "#fff",
    },
    action: {
      disabledBackground: "rgb(241, 241, 241)",
    },
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h6: "h5",
      },
    },
  },
  shape: {
    borderRadius: borderRadius.default,
  },
  typography: {
    fontFamily: '"Heebo", sans-serif',
    h1: {
      fontSize: fontSizes.h1,
      fontWeight: 300,
      lineHeight: lineHeights.h1,
      letterSpacing: "6px",
    },
    h2: {
      fontSize: fontSizes.h2,
      fontWeight: 300,
      lineHeight: lineHeights.h2,
    },
    h3: {
      fontSize: fontSizes.h3,
      fontWeight: 300,
      letterSpacing: "0.014rem",
      lineHeight: lineHeights.h3,
    },
    h4: {
      fontSize: fontSizes.h4,
      fontWeight: 300,
      letterSpacing: "0.013rem",
      lineHeight: lineHeights.h4,
    },
    h5: {
      fontSize: fontSizes.h5,
      fontWeight: 300,
      lineHeight: lineHeights.h5,
    },
    h6: {
      fontSize: fontSizes.h6,
      fontWeight: 300,
      lineHeight: lineHeights.h6,
    },
    subtitle1: {
      fontSize: fontSizes.subtitle1,
      fontWeight: 300,
      lineHeight: lineHeights.subtitle1,
    },
    subtitle2: {
      fontSize: fontSizes.subtitle2,
      fontWeight: 300,
      lineHeight: lineHeights.subtitle2,
    },
    body1: {
      fontSize: fontSizes.body1,
      fontWeight: 300,
      lineHeight: "1.25rem",
    },
    body2: {
      fontSize: fontSizes.body2,
      fontWeight: 300,
      lineHeight: "1rem",
    },
    overline: {
      fontSize: fontSizes.overline,
      fontWeight: 300,
      lineHeight: "1rem",
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: "none !important",
      },
    },
    MuiFab: {
      root: {
        boxShadow: "none !important",
      },
    },
    MuiButtonBase: {
      root: {
        boxShadow: "none !important",
      },
    },
    MuiOutlinedInput: {
      root: {
        "& fieldset": {
          borderWidth: "1px",
        },
      },
    },
    MuiButton: {
      root: {
        boxShadow: "none !important",
        borderWidth: "2px",
        fontWeight: "bold",
        letterSpacing: 1.25,
        paddingLeft: 20,
        paddingRight: 20,
      },
      contained: {},
      outlined: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 18,
        paddingRight: 18,
      },
      outlinedPrimary: {
        borderWidth: "2px",
        borderColor: primaryColor.main,
        "&:hover": {
          borderWidth: "2px",
        },
      },
      outlinedSecondary: {
        borderWidth: "2px",
        borderColor: secondaryColor.main,
        "&:hover": {
          borderWidth: "2px",
        },
      },
    },
  },
})

export default theme
