import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { AlternatePage, SitePage } from "./types"
import {
  SitePages as SitePagesQuery,
  SitePages_allSitePage_nodes as SitePageNodes,
} from "./pageTypes"
import { toMap } from "../../utils/arrayUtils"

export interface SitePages {
  getPage(id: string): SitePage | undefined
  getPagePath(id: string): string | undefined
}

export class SitePagesMap implements SitePages {
  private map = new Map<string, SitePage>()
  constructor(pages: SitePage[]) {
    this.map = toMap(pages, (x) => x.id)
  }

  getPage(id: string) {
    return this.map.get(id)
  }

  getPagePath(id: string) {
    return this.getPage(id)?.path
  }
}

export const SitePagesContext = React.createContext<SitePages>(
  new SitePagesMap([])
)

const toSitePage = (page: SitePageNodes): SitePage => ({
  id: page.context?.id ?? "",
  path: page.path,
  source: page.context?.source ?? "",
  alternatePages: (page.context?.alternateLanguages as AlternatePage[]) ?? [],
})

export const SitePagesProvider = ({ children }: any) => {
  const { allSitePage } = useStaticQuery<SitePagesQuery>(graphql`
    query SitePages {
      allSitePage {
        nodes {
          path
          context {
            id
            alternateLanguages {
              lang
              id
            }
          }
        }
      }
    }
  `)

  return (
    <SitePagesContext.Provider
      value={new SitePagesMap(allSitePage.nodes.map(toSitePage))}
    >
      {children}
    </SitePagesContext.Provider>
  )
}
