import React from "react"
import { importIcons } from "./icons"
import { MUIThemeProvider } from "../themes/provider"
import { WebsiteContextProvider } from "../context/contents/websiteContext"
import Scripts from "./scripts"
import { SitePagesProvider } from "../root/common/context/pages"
import PrismicProviders from "../root/contents/prismic/providers"

interface Params {
  element: any
}

export const wrapRootElement = ({ element }: Params) => {
  importIcons()
  return (
    <>
      <SitePagesProvider>
        <PrismicProviders>
          <WebsiteContextProvider>
            <MUIThemeProvider withBaseline>{element}</MUIThemeProvider>
          </WebsiteContextProvider>
        </PrismicProviders>
      </SitePagesProvider>
      <Scripts />
    </>
  )
}

export * from "./fragments"
