import React from "react"
import { ThemeProvider, CssBaseline } from "@material-ui/core"
import theme from "./default"

interface Props {
  children: React.ReactNode
  withBaseline: boolean
}

const MUIThemeProvider = ({ children, withBaseline }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      {withBaseline === true ? <CssBaseline /> : undefined}
      {children}
    </ThemeProvider>
  )
}

MUIThemeProvider.defaultProps = {
  withBaseline: false,
}

export { MUIThemeProvider }
