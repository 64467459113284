export const toMap = <TKey, TVal>(
  array: TVal[],
  key: (val: TVal) => TKey
): Map<TKey, TVal> => {
  const map = new Map<TKey, TVal>()
  array.forEach((x) => map.set(key(x), x))
  return map
}

const compareFields = <T>(a: T, b: T, selector: (value: T) => any): number => {
  if (selector(a) < selector(b)) {
    return -1
  }
  if (selector(a) > selector(b)) {
    return 1
  }
  return 0
}

type ArrayComparer<T> = (a: T, b: T) => number

export const byField = <T>(selector: (value: T) => any): ArrayComparer<T> => {
  return function (a: T, b: T) {
    return compareFields(a, b, selector)
  }
}

export const byFieldDesc = <T>(
  selector: (value: T) => any
): ArrayComparer<T> => {
  return function (a: T, b: T) {
    return -compareFields(a, b, selector)
  }
}

export const sort = <T>(array: T[], ...comparers: ArrayComparer<T>[]) =>
  array.sort((a, b) => {
    for (const comparer of comparers) {
      const comparation = comparer(a, b)
      if (comparation !== 0) {
        return comparation
      }
    }

    return 0
  })
