export const btnTransitions =
  "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out"

export const primaryColor = {
  light: "#ff6e5e",
  main: "#ff3333",
  dark: "#c3000a",
}

export const secondaryColor = {
  light: "#ffff50",
  main: "#ffcc00",
  dark: "#c79c00",
}

export const successColor = {
  light: "#d6ffa5",
  main: "#a2e874",
  dark: "#70b545",
}

export const inputFontSizes = {
  small: "0.75rem",
  medium: "1.0625rem",
  large: "1.375rem",
}

export const labelFontSizes = {
  small: "0.625rem",
  medium: "0.75rem",
  large: "1rem",
}

export const fontSizes = {
  h1: "3.125rem", // 50px
  h2: "2.1875rem", // 35px
  h3: "2rem", // 32px
  h4: "1.875rem", // 30px
  h5: "1.625rem", // 26px
  h6: "1.375rem", // 22px
  subtitle1: "1.125rem", // 18px
  subtitle2: "1rem", // 16px
  body1: "1rem", // 16px
  body2: "0.875rem", // 14px
  overline: "0.875rem", // 14px
}

export const lineHeights = {
  h1: "4.5625rem", // 73px
  h2: "3.25rem", // 52px
  h3: "3rem", // 48px
  h4: "2.75rem", // 44px
  h5: "2.375rem", // 38px
  h6: "1.75rem", // 28px
  subtitle1: "1.5rem", // 24px
  subtitle2: "1.375rem", // 22px
  body1: "1rem", // 16px
  body2: "0.875rem", // 14px
  overline: "0.875rem", // 14px
}

export const brandColors = {
  facebook: "#3b5998",
  google: "#dd4b39",
  whatsapp: "#25D366",
  twitter: "#1DA1F2",
  gmail: "#c71610",
}

export const borderRadius = {
  default: 6,
  medium: 12,
  large: 16,
}

export const backgroundColors = {
  dark: "#121711",
}

export const fontWeights = {
  bold: 900,
  medium: 700,
  regular: 500,
  light: 300,
  lighter: 100,
}

export const borderColors = {
  medium: "rgba(0, 0, 0, 0.12)",
}
