import * as queryString from "query-string"
import { WindowLocation } from "@reach/router"
import { trim } from "./stringUtils"

export const getQueryParam = (
  location: WindowLocation | Location | undefined,
  name: string
): string =>
  location ? (queryString.parse(location.search)[name] as string) : ""

export const toQueryParams = (data: any) => queryString.stringify(data)
export const fromQueryParams = (query: string) => queryString.parse(query)

export const getPathSegments = (path: string) => trim(path, "/").split("/")
export const makeRelative = (url: string) => {
  const u = new URL(url)
  return `${u.pathname}${u.search}`
}
