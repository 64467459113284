import { graphql, useStaticQuery } from "gatsby"
import { WebsiteThemeQuery } from "../../types/theme"

export const usePrismicThemesQuery = () =>
  useStaticQuery<WebsiteThemeQuery>(graphql`
    query WebsiteThemeQuery {
      allPrismicWebsiteTheme {
        nodes {
          data {
            action_active_color
            action_disabled_color
            action_focus_color
            action_hover_color
            action_selected_color
            body1_font_color
            body1_font_size
            body1_font_weight
            body1_letter_spacing
            body1_line_height
            body2_font_color
            body2_font_size
            body2_font_weight
            body2_letter_spacing
            body2_line_height
            border_radius_default
            border_radius_large
            border_radius_medium
            box_shadow_disabled
            box_shadow_override
            buttons_default_box_shadow
            buttons_default_border_width
            buttons_default_font_weight
            buttons_default_letter_spacing
            buttons_default_padding_bottom
            buttons_default_padding_left
            buttons_default_padding_right
            buttons_default_padding_top
            custom_colors {
              color_value
              color_name
            }
            dark_background_color
            dark_background_image {
              url
            }
            default_background_color
            default_background_image {
              url
            }
            default_font_family
            default_font_weight
            default_theme
            error_dark
            error_light
            error_main
            font_import_urls {
              font_import_url
            }
            font_weight_bold
            font_weight_boldest
            font_weight_light
            font_weight_lightest
            font_weight_normal
            h1_font_color
            h1_font_size
            h1_font_weight
            h1_line_height
            h2_font_color
            h1_letter_spacing
            h2_font_size
            h2_font_weight
            h2_letter_spacing
            h2_line_height
            h3_font_color
            h3_font_size
            h3_font_weight
            h3_letter_spacing
            h3_line_height
            h4_font_color
            h4_font_weight
            h4_font_size
            h4_line_height
            h4_letter_spacing
            h5_font_size
            h5_font_color
            h5_font_weight
            h5_line_height
            h5_letter_spacing
            h6_font_color
            h6_font_size
            h6_font_weight
            h6_letter_spacing
            h6_line_height
            info_dark
            info_light
            info_main
            overline_font_color
            overline_font_size
            overline_font_weight
            overline_letter_spacing
            overline_line_height
            primary_dark
            primary_light
            secondary_dark
            primary_main
            secondary_light
            secondary_main
            subtitle1_font_color
            subtitle1_font_weight
            subtitle1_letter_spacing
            subtitle1_font_size
            subtitle1_line_height
            subtitle2_font_color
            subtitle2_font_size
            subtitle2_font_weight
            subtitle2_letter_spacing
            subtitle2_line_height
            success_dark
            success_light
            success_main
            text_disabled_color
            text_hint_color
            text_primary_color
            text_secondary_color
            warning_light
            warning_main
            warning_dark
            theme_name
          }
          lang
        }
      }
    }
  `)
