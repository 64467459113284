import React, { useEffect, useState } from "react"
import Script from "react-load-script"
import { getQueryParam } from "../../../utils/urlUtils"

const PrismicPreview = () => {
  const [isPreview, setIsPreview] = useState(false)

  useEffect(() => {
    const preview = getQueryParam(window.location, "preview")
    if (preview) {
      setIsPreview(true)
    }
  }, [])

  if (isPreview) {
    return (
      <Script url="https://static.cdn.prismic.io/prismic.js?repo=wigo-corp&new=true" />
    )
  }
  return <></>
}

export default PrismicPreview
