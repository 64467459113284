import React from "react"
import { AppThemesContext } from "../../../../common/context/theme"
import { toAppTheme } from "./converter"
import { usePrismicThemesQuery } from "./query"

interface Props {
  children: React.ReactNode
}

export const PrismicWebsiteThemeProvider = ({ children }: Props) => {
  const { allPrismicWebsiteTheme } = usePrismicThemesQuery()
  return (
    <AppThemesContext.Provider
      value={allPrismicWebsiteTheme.nodes.map(toAppTheme)}
    >
      {children}
    </AppThemesContext.Provider>
  )
}
