export interface LocaleSettings {
  id: string
  path: string | undefined
  code: string
}

export const FALLBACK_LANGUAGE = "en-us"

export const locales: LocaleSettings[] = [
  {
    id: "it-it",
    path: undefined,
    code: "it",
  },
  {
    id: "en-us",
    path: "en",
    code: "en",
  },
]

export const getLocaleSettings = (locale: string) =>
  locales.find((x) => x.id === locale)
